const prefix = 'boost-sd__';

export const clsProductItemInstantSearch = `.${prefix}suggestion-queries-item--product`;
export const clsProductItem = `.${prefix}product-item, .${prefix}product-item-list-view-layout`;
export const clsRecommendation = `.${prefix}recommendation`;
export const clsQuickView = `.${prefix}btn-quick-view`; // TODO - current quick-view not have class unique
export const clsAddToCart = boostSDAppConfig?.analytics?.selectorAddToCart
  ? boostSDAppConfig?.analytics?.selectorAddToCart
  : `.${prefix}btn-add-to-cart, form[action="/cart/add"] *[type="submit"], form[action="/cart/add"] *[name="add"]`; // TODO - class add to cart of our app and shopify theme
export const clsBuyNow = boostSDAppConfig?.analytics?.selectorBuyNow
  ? boostSDAppConfig?.analytics?.selectorBuyNow
  : `.${prefix}btn-buy-now, .shopify-payment-button__button, .shopify-payment-button, #dynamic-checkout-cart`; // class buy now of our app and shopify theme
export const clsModalQuickView = `.${prefix}quick-view`;
