import { USER_ACTION, DATA_NOT_SENT, CART_TOKEN_LATEST } from '../constant';
import { clsAddToCart, clsBuyNow, clsModalQuickView, clsRecommendation } from '../selector';
import {
  addProductClickData,
  sendProductClickData,
  isProductPage,
  buildClickProductData,
  isClickAddToCart,
  getLocalStorage,
} from '../utils';

/**
 * Handle analytic on click add to cart in product page.
 * Save the clicked product data to localStorage.
 * Send product click data
 * @param {Event} event - the click event
 */
export const onClickAddToCartOrBuyNowInProductPage = (event) => {
  if (!event || !event.target) {
    return;
  }

  const activeElement = event.target;

  const buyNow = activeElement.closest(clsBuyNow);
  const addToCartElement = activeElement.closest(clsAddToCart) || isClickAddToCart(activeElement);

  if (addToCartElement || buyNow) {
    let data = {
      pid: boostSDAppConfig?.generalSettings?.product_id?.toString(),
      u: buyNow ? USER_ACTION.BUY_NOW : USER_ACTION.ADD_TO_CART,
    };

    let action = '';

    // case click add to cart in recommendation on product page
    const recommendation = activeElement.closest(clsRecommendation);
    if (recommendation) {
      const parentId = recommendation.parentElement?.id;
      if (parentId) {
        action = 'recommend';
        data.wid = parentId.replace('boost-sd-widget-', '');
      }
    }

    // case open quick view after click add to cart in recommendation on product page
    const quickView = activeElement.closest(clsModalQuickView);
    if (quickView) {
      const productId = quickView.getAttribute('product-id');
      if (productId) {
        data.pid = productId;
        action = 'recommend';
      }
    }

    data = buildClickProductData(data.pid, data.u, action, data);

    // will not run if data not existed -> case return false of v2 run v3
    if (!data) return;

    addProductClickData(data);
    sendProductClickData(data, true);
  }
};

/**
 * Init analytics on product page.
 * Find and send a product click data in localStorage to server.
 */
export const initOtherPage = () => {
  if (!isProductPage()) return;

  const data = getLocalStorage(DATA_NOT_SENT);
  const cartTokenLatest = getLocalStorage(CART_TOKEN_LATEST);
  if (data && data.ct !== cartTokenLatest) {
    sendProductClickData(data, true);
    localStorage.removeItem(DATA_NOT_SENT);
  }
  console.log('register event initOtherPage');
  document.addEventListener('click', onClickAddToCartOrBuyNowInProductPage, true);
};

initOtherPage();
