import { ACTION, USER_ACTION } from '../constant';
import { clsProductItemInstantSearch } from '../selector';
import { addProductClickData, buildClickProductData, sendProductClickData } from '../utils';

export const onClickProductInstantSearch = (event) => {
  if (!event || !event.target) return;

  // Check for keyboard enter event
  if (event.type == 'keydown' && event.key !== 'Enter') return;

  const activeElement = event.target;
  const productElement = activeElement.closest(clsProductItemInstantSearch);

  if (!productElement) return;
  const productId = productElement.getAttribute('data-id');

  if (!productId) return;
  const data = buildClickProductData(productId, USER_ACTION.VIEW_PRODUCT, ACTION.SUGGEST);

  addProductClickData(data);
  sendProductClickData(data);
};

export const initInstantSearch = (enable) => {
  if (!enable) return null;

  // TODO dev test
  console.log('register event initInstantSearch');

  document.addEventListener('click', onClickProductInstantSearch, true);
  document.addEventListener('keydown', onClickProductInstantSearch, true);
};

initInstantSearch(true);
