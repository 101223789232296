import {
  ACTION,
  RECOMMENDATION_ACTIVE_KEY,
  REQUEST_ID_MORE_INFORMATION_KEY,
  USER_ACTION,
} from '../constant';
import {
  clsAddToCart,
  clsBuyNow,
  clsProductItem,
  clsQuickView,
  clsRecommendation,
  clsModalQuickView,
} from '../selector';
import {
  isSearchPage,
  buildClickProductData,
  addProductClickData,
  sendProductClickData,
  isProductPage,
  isCollectionPage,
  setLocalStorage,
  getLocalStorage,
} from '../utils';

/**
 * Handle analytic on click product list in the collection/search page.
 * Save the clicked product data to localStorage.
 * @param {Event} event - the click event
 */
export const onClickProductInFilterRecommendResult = (event) => {
  if (!event || !event.target) return;

  const activeElement = event.target;
  // We will double check action when have requestId because searchPage or collection Page can contains recommend
  let action = isSearchPage() ? ACTION.SEARCH : isCollectionPage ? ACTION.FILTER : ACTION.RECOMMEND;

  // Click recommendation set action
  if (activeElement.closest(clsRecommendation)) {
    action = ACTION.RECOMMEND;
  }

  // Not send event when click product in product page if it is not product of recommendation
  if (action !== ACTION.RECOMMEND && isProductPage()) {
    return;
  }

  let userAction = USER_ACTION.VIEW_PRODUCT;

  if (activeElement.closest(clsQuickView)) {
    userAction = USER_ACTION.QUICK_VIEW;
  } else if (activeElement.closest(clsBuyNow)) {
    userAction = USER_ACTION.BUY_NOW;
  } else if (activeElement.closest(clsAddToCart)) {
    userAction = USER_ACTION.ADD_TO_CART;
  }

  let productId = '';
  const productItem = activeElement.closest(clsProductItem);
  if (productItem) {
    productId = productItem.getAttribute('id') || '';
  } else {
    // Add to cart and buy now within modal
    if (userAction == USER_ACTION.ADD_TO_CART || userAction == USER_ACTION.BUY_NOW) {
      productId = activeElement.closest(clsModalQuickView)?.getAttribute('product-id');
    }
  }

  if (!productId) return;

  // quick view modal add to cart or buy now
  if (activeElement.closest(clsModalQuickView)) {
    const recommendationActive = getLocalStorage(RECOMMENDATION_ACTIVE_KEY);
    if (recommendationActive && productId === recommendationActive.pid) {
      action = ACTION.RECOMMEND;
    }
  }

  const optionData = {};

  // add new params if recommendation. open if api require passes params tracking when recommendation
  if (action === ACTION.RECOMMEND) {
    const recommendationElement = activeElement.closest(clsRecommendation);
    const parentDiv = recommendationElement?.parentNode;
    if (parentDiv?.id) {
      const recommendationWidgetId = parentDiv.id?.replace('boost-sd-widget-', '');
      const recommendationWidgetType = parentDiv.classList[0]?.replace('boost-sd-', '');

      optionData.wid = recommendationWidgetId;
      optionData.rt = recommendationWidgetType;

      const moreInfo = getLocalStorage(REQUEST_ID_MORE_INFORMATION_KEY);

      if (Object.keys(moreInfo)?.length > 0) {
        const requestIdActive = Object.keys(moreInfo).find(
          (key) => moreInfo[key].wid === recommendationWidgetId
        );

        if (!optionData.rt && moreInfo[requestIdActive])
          optionData.rt = moreInfo[requestIdActive]?.rt;

        optionData.rid = requestIdActive;

        setLocalStorage(RECOMMENDATION_ACTIVE_KEY, {
          rid: requestIdActive,
          wid: optionData.wid,
          rt: optionData.rt,
          pid: productId,
        });
      }
    }
  }

  // Not handle for case add_to_cart and buy_now in product page because have register  2 event on product page
  if (isProductPage() && [USER_ACTION.ADD_TO_CART, USER_ACTION.BUY_NOW].includes(userAction))
    return;

  const data = buildClickProductData(productId, userAction, action, optionData);

  addProductClickData(data);
  sendProductClickData(data, [USER_ACTION.ADD_TO_CART, USER_ACTION.BUY_NOW].includes(userAction));
};

export const initCollectionSearchPage = () => {
  // TODO Dev test
  console.log('register event initCollectionSearchPage');
  document.addEventListener('click', onClickProductInFilterRecommendResult, true);
};

initCollectionSearchPage();
