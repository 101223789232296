export const ANALYTICS_KEY = 'boostSDAnalytics';
export const SESSION_KEY = 'boostSDSessionId';
export const RECENTLY_VIEWED_PRODUCT = 'boostSDRecentlyViewedProduct';
export const USER_ACTION = {
  VIEW_PRODUCT: 'view_product',
  QUICK_VIEW: 'quick_view',
  ADD_TO_CART: 'add_to_cart',
  BUY_NOW: 'buy_now',
};

export const ACTION = {
  FILTER: 'filter',
  SEARCH: 'search',
  SUGGEST: 'suggest',
  RECOMMEND: 'recommend',
};

export const ANALYTICS_URL = 'https://lambda.mybcapps.com/e';

// matched key of APP defined
export const REQUEST_ID_FILTER_KEY = 'boostSDRequestIdFilterKey';
export const REQUEST_ID_SEARCH_KEY = 'boostSDRequestIdSearchKey';
export const REQUEST_ID_SUGGEST_KEY = 'boostSDRequestIdSuggestKey';
export const REQUEST_ID_RECOMMENDATION_KEY = 'boostSDRequestIdRecommendationKey';
export const REQUEST_ID_SAVE_INFO_REQUEST_KEY = 'boostSDRequestIdSaveInfoRequest';
export const REQUEST_ID_PRODUCT_ID_CLICKED_KEY = 'boostSDRequestIdProductClickedKey';
export const REQUEST_ID_MORE_INFORMATION_KEY = 'boostSDRequestIdMoreInformationKey';
export const RECOMMENDATION_ACTIVE_KEY = 'boostSDRecommendationActiveKey';
export const SEARCH_RESULTS_KEY = 'boostSdSearchResults';
export const DATA_NOT_SENT = 'boostSdDataNotSent';
export const CART_TOKEN_LATEST = 'boostSdCartTokenLatest';
