import {
  ACTION,
  ANALYTICS_KEY,
  ANALYTICS_URL,
  REQUEST_ID_FILTER_KEY,
  REQUEST_ID_RECOMMENDATION_KEY,
  REQUEST_ID_SAVE_INFO_REQUEST_KEY,
  REQUEST_ID_SEARCH_KEY,
  REQUEST_ID_SUGGEST_KEY,
  SESSION_KEY,
  USER_ACTION,
  REQUEST_ID_PRODUCT_ID_CLICKED_KEY,
  RECOMMENDATION_ACTIVE_KEY,
  SEARCH_RESULTS_KEY,
  DATA_NOT_SENT,
  CART_TOKEN_LATEST,
} from './constant';

var CART_TOKEN = '';
const currentSessionId = localStorage.getItem(SESSION_KEY);
var SESSION = currentSessionId ? currentSessionId?.replaceAll('"', '') : '';
//TODO: some data old session_id include " -> remove and save new. After 2 week can delete
if (currentSessionId && currentSessionId?.includes('')) {
  localStorage.setItem(SESSION_KEY, currentSessionId?.replaceAll('"', ''));
}

/**
 * Generates a random unique session ID
 * @return {string} random unique ID
 */
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Generates a random unique Event ID
 * @return {string} random unique ID
 */
export const createEventId = () => {
  return generateUUID();
};

/**
 *  get a key in localStorage and Parse value
 * @return {any} value
 */
export const getLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);

    return null;
  } catch {
    return null;
  }
};

/**
 *  save a key to localStorage
 */
export const setLocalStorage = (key, value) => {
  try {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, '');
    }
  } catch {}
};

/**
 *  get (or create new sessionId if not existed) a sessionId in localStorage
 *  @return {string} value
 */
export const getSessionId = () => {
  if (SESSION) {
    return SESSION;
  } else {
    SESSION = generateUUID();
    localStorage.setItem(SESSION_KEY, SESSION);
  }

  return SESSION;
};

/**
 *  refresh cart token
 */
export const refreshCartToken = (dataToRetry) => {
  fetch('/cart.js')
    .then((res) => res.json())
    .then((cart) => {
      const cartToken = cart.token;
      CART_TOKEN = cartToken;

      // SAVE cart token to storage <=> the same key cartToken of shopify save
      cartToken && localStorage.setItem('cartToken', cartToken);

      if (dataToRetry) {
        dataToRetry.ct = cartToken;
        sendProductClickData(dataToRetry, false);
      }
    })
    .catch((error) => {
      // send add to cart without token cart token
      sendProductClickData(dataToRetry, false);
      console.log('error call refresh cartToken, send tracking without cart token', error);
    });
};

/*
 * check page is collection page
 */
export const isCollectionPage = () => {
  return boostSDAppConfig?.generalSettings?.page === 'collection';
};

/*
 * check page is cart page
 */
export const isCartPage = () => {
  return boostSDAppConfig?.generalSettings?.page === 'cart';
};

/*
 * check page is searchPage
 */
export const isSearchPage = () => {
  return boostSDAppConfig?.generalSettings?.page === 'search';
};

/**
 * Check if the current page is Product page
 */
export const isProductPage = () => {
  return boostSDAppConfig?.generalSettings?.page === 'product';
};

/**
 * Check if the current page is Home page
 */
export const isHomePage = () => {
  return boostSDAppConfig?.generalSettings?.page === 'index';
};

export const getCustomerId = () => {
  // https://community.shopify.com/c/shopify-apis-and-sdks/current-best-way-to-get-logged-in-customer-id/td-p/482773
  return (
    __st?.id ||
    meta?.page?.customerId ||
    ShopifyAnalytics?.meta?.page?.customerId ||
    ShopifyAnalytics?.lib?.user()?.traits()?.uniqToken
  );
};

/**
 * get current page
 */
export const getCurrentPage = () => {
  let currentPage = '';

  switch (true) {
    case isCollectionPage():
      currentPage = 'collection_page';
      break;

    case isSearchPage():
      currentPage = 'search_page';
      break;

    case isProductPage():
      currentPage = 'product_page';
      break;

    case isCartPage():
      currentPage = 'cart_page';
      break;

    case isHomePage():
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
};

export const buildRequestIdFromAction = (productId, action) => {
  let REQUEST_ID_STORE_PRODUCTS = '';

  switch (action) {
    case ACTION.FILTER:
      REQUEST_ID_STORE_PRODUCTS = REQUEST_ID_FILTER_KEY;
      break;

    case ACTION.SEARCH:
      REQUEST_ID_STORE_PRODUCTS = REQUEST_ID_SEARCH_KEY;
      break;

    case ACTION.SUGGEST:
      REQUEST_ID_STORE_PRODUCTS = REQUEST_ID_SUGGEST_KEY;
      break;

    case ACTION.RECOMMEND:
      REQUEST_ID_STORE_PRODUCTS = REQUEST_ID_RECOMMENDATION_KEY;
      break;
  }

  if (!REQUEST_ID_STORE_PRODUCTS) return '';

  // one page can contains multi recommendation
  if (action === ACTION.RECOMMEND) {
    const recommendationActive = getLocalStorage(RECOMMENDATION_ACTIVE_KEY);

    if (recommendationActive && recommendationActive.pid === productId) {
      return recommendationActive.rid;
    }
  }

  const requestIdsContainProducts = getLocalStorage(REQUEST_ID_STORE_PRODUCTS);

  if (requestIdsContainProducts) {
    const keys = Object.keys(requestIdsContainProducts);
    for (let i = 0; i < keys.length; i++) {
      if (requestIdsContainProducts[keys[i]]?.includes(Number(productId))) {
        return keys[i];
      }
    }
  }

  return '';
};

const getInfoByRequestId = (requestId) => {
  const infoBaseOnRequestId = getLocalStorage(REQUEST_ID_SAVE_INFO_REQUEST_KEY);

  if (infoBaseOnRequestId && infoBaseOnRequestId[requestId]) {
    return infoBaseOnRequestId[requestId];
  }

  return {
    query_string: '',
    action: 'filter',
  };
};

const getProductsFromSementicsByQueryString = (queryString) => {
  const searchResults = getLocalStorage(SEARCH_RESULTS_KEY) || {};
  const data = searchResults[queryString];

  if (data && data?.meta?.product_from_semantics) {
    return data?.meta?.product_from_semantics;
  }
  return [];
};

const getMaxValue = (localStorageKey, maxRecord = 50) => {
  let preValue = getLocalStorage(localStorageKey) || {};

  // KEEP max quality of record save
  if (Object.keys(preValue).length >= maxRecord) {
    const preValueTemp = {};
    const keys = Object.keys(preValue);
    const values = Object.values(preValue);

    for (let i = 0; i < maxRecord; i++) {
      preValueTemp[keys[i]] = values[i];
    }

    preValue = preValueTemp;
  }

  return preValue;
};

/*
 * Build data for product Click
 */
export const buildClickProductData = (productId, userAction, action, optionData = {}) => {
  let requestId = optionData?.rid ? optionData?.rid : buildRequestIdFromAction(productId, action);

  if (action) {
    const preValue = getMaxValue(REQUEST_ID_PRODUCT_ID_CLICKED_KEY, 3);
    // delete old productId to push override new requestId
    if (preValue[Number(productId)]) {
      delete preValue[Number(productId)];
    }

    const productIdCLicked = {
      [Number(productId)]: requestId,
      ...preValue,
    };

    setLocalStorage(REQUEST_ID_PRODUCT_ID_CLICKED_KEY, productIdCLicked);
  }

  if (!action || !requestId) {
    const getRequestIdFromCLickedData = getLocalStorage(REQUEST_ID_PRODUCT_ID_CLICKED_KEY);
    // break when v2 use tracking event v3
    if (!getRequestIdFromCLickedData || !getRequestIdFromCLickedData[Number(productId)])
      return false;

    requestId = getRequestIdFromCLickedData[Number(productId)];
  }

  // get query_string from requestId
  const query_string = getInfoByRequestId(requestId).query_string;

  // Merge quick_view and view_product when sending to backend
  if (userAction === USER_ACTION.QUICK_VIEW) {
    userAction = USER_ACTION.VIEW_PRODUCT;
  }

  if (!action) {
    action = getInfoByRequestId(requestId).action;
  }

  let fromSemantics = false;
  if (action === ACTION.SUGGEST) {
    const semanticProducts = getProductsFromSementicsByQueryString(query_string);
    if (semanticProducts.includes(productId)) fromSemantics = true;
  }

  const cartToken = CART_TOKEN;

  return {
    tid: Shopify.shop, // tenant_id
    qs: query_string,
    eid: createEventId(), // event_id
    rid: requestId, // request_id
    ct: cartToken || localStorage.getItem('cartToken'), // cart token
    pid: productId, // product_id
    t: new Date().toISOString(), // clicked at
    u: userAction, // user action
    a: action || 'other', // action
    r: document.referrer, // referrer
    sid: getSessionId(), // session_id
    cid: getCustomerId(), // customer_id
    pg: getCurrentPage(), // current page type,
    fromSemantics,
    ...optionData,
  };
};

/**
 * Add product click data in local storage.
 * @param {Object} data - product click data
 */
export const addProductClickData = (data) => {
  // Get data list from local storage
  var dataList = getLocalStorage(ANALYTICS_KEY);
  if (!Array.isArray(dataList)) dataList = [];

  // Add new data to the list, without duplicated id
  const newDataList = dataList.filter((x) => x.pid != data.productId);
  newDataList.push(data);

  setLocalStorage(ANALYTICS_KEY, newDataList);
};

export const removeProductClickData = (productId) => {
  // Get data list from local storage
  var dataList = getLocalStorage(ANALYTICS_KEY);
  if (!Array.isArray(dataList)) return;

  // Filter for products that doesn't match the id
  var newDataList = dataList.filter((x) => x.pid != productId);
  setLocalStorage(ANALYTICS_KEY, newDataList);
};

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

/**
 * Send product click data to server.
 * @param {Object} data - product click data
 * @param {boolean} triedToGetToken - tried to get cart token by calling cart.js or not
 */
export const sendProductClickData = (data, triedToGetToken) => {
  const END_POINT = window.boostSDAppConfig?.api?.analyticsUrl || ANALYTICS_URL;

  // delete recommendation active key when sent event add_to_cart or buy now success
  if (data.a === 'recommend' && ['add_to_cart', 'buy_now'].includes(data.u)) {
    localStorage.removeItem(RECOMMENDATION_ACTIVE_KEY);
  }

  if (triedToGetToken) {
    setLocalStorage(DATA_NOT_SENT, data);
    // settimeout make sure POST data -> Add.js -> Get Cart token -> send event
    return setTimeout(() => {
      return refreshCartToken(data);
    }, boostSDAppConfig?.analytics?.timeoutSend || 1200);
  }

  postData(END_POINT, data).then((res) => {
    // onsuccess then error = ''
    if (res.error === '') {
      data.ct && setLocalStorage(CART_TOKEN_LATEST, data.ct);
      removeProductClickData(data.pid);
      localStorage.removeItem(DATA_NOT_SENT);
    }
  });
};

export function isClickAddToCart(activeElement) {
  if (!activeElement) return false;

  const addToCartKeywords = [
    'add to cart',
    'add-to-cart',
    'add to bag',
    'add_to_cart',
    'addtocart',
    'data-product-form-add',
    'name="add"',
  ];

  const innerText = activeElement?.innerHTML?.toLowerCase() || '';

  return addToCartKeywords.some((keyword) => innerText.includes(keyword));
}
